import React from "react";
import ContactForm from './ContactForm'
import projectsData from "./ProjectsData";

const baseURL = "https://jumpfast-cdn.blr1.cdn.digitaloceanspaces.com/portfolio/assets";

const CATEGORIES = [
  "Web Presence",
  "Native Apps",
  "Enterprise Dashboards",
]

const App = () => {
  return (<div className="">
    <img src={baseURL + `/jumpfast-logo-small.png`} className="img-fluid logo" alt="" />
    <div className="hero-gradient h-75vh position-relative container-fluid">
      <div className="bottom-fade"></div>
      <div className="d-flex align-items-center h-100 flex-column">
        <div className="row justify-content-center mt-5 mt-lg-0 z-index-1">
          <div className="col-12 col-lg-6 h-100 d-flex flex-column justify-content-center mt-5">
            <div>
              <h1 className="display-1 fw-800 mb-2 w-100 text-dark">JumpFast Technologies</h1>
              <h2 className="h4 fw-400 text-dark">From <strong>Idea</strong> to <strong>Impact</strong>: Accelerating Startup Tech 🚀</h2>
              <p className="mt-4 d-none d-lg-block">Our mission is to inspire and enable the creators and innovators of today and tomorrow, fostering a culture of endless possibilities. At Tech Pioneers of Tomorrow, we believe in the power of ideas and the limitless potential of technology. We are a passionate team of experts who thrive on pushing the boundaries of what's possible, bringing visionary tech product ideas to life.</p>
              <a href="#contact-us" className="btn btn-lg btn-outline-primary mt-3 mt-lg-5 rounded-0 px-5 py-3">hire us</a>
            </div>
          </div>
          <div className="col-4 d-none d-lg-flex justify-content-center ">
            <img src={baseURL + `/hero.png`} className="h-100 img-fluid" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid mt-lg-5 pt-lg-5 mt--5 pb-5">
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1 z-index-1">
          <h3 className="display-4 fw-800 text-dark opacity-85 mb-1 mt-lg-5">Our Projects</h3>
          <p>Showcase of Success: Exploring Our Technological Marvels</p>
          {CATEGORIES.map((category, idx) =>
            <div className="row mt-3" key={category}>
              <div className="col-12">
                <h3 className="display-5 fw-800 text-dark opacity-85">{category}</h3>
                <hr className="mb-4" />
              </div>
              {projectsData.filter(({ category }) => category === idx + 1).map((item, i) => (<div className="col-12 col-lg-4 mb-4" key={i}>
                <div className="bg-white rounded shadow-sm overflow-hidden h-100 d-flex flex-column">
                  <img src={`${item.img}`} width="100%" className="img-fluid border-bottom" alt={item.title} />
                  <div className="py-2 px-3 h-100 d-flex flex-column">
                    <p className="mb-0 mt-1 h5">{item.title}</p>
                    {/* <div className="small">{item.tags.map((tag) => <span className="badge badge-info  badge-pill mr-1 fw-200" key={tag}>{tag}</span>)}</div> */}
                    <p className="mb-3">{item.para}</p>
                    <div className="d-flex mt-auto">
                      {item.link && <a href={item.link} className="btn btn-sm btn-outline-primary w-100 py-2 justify-content-center d-flex align-items-center" target="_blank" rel="noreferrer noopener">
                        <svg className="mr-1" xmlns="http://www.w3.org/2000/svg" width='22px' viewBox="0 0 24 24" fill="currentColor"><title>open-in-new</title><path d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" /></svg> Explore
                      </a>}
                      {item.android && <a href={item.android} className="w-100 justify-content-center btn btn-sm btn-outline-primary d-flex align-items-center ml-2" target="_blank" rel="noreferrer noopener">
                        <svg className="mr-1" xmlns="http://www.w3.org/2000/svg" width='24px' viewBox="0 0 24 24" fill="currentColor"><title>android</title><path fill="inherit" d="M16.61 15.15C16.15 15.15 15.77 14.78 15.77 14.32S16.15 13.5 16.61 13.5H16.61C17.07 13.5 17.45 13.86 17.45 14.32C17.45 14.78 17.07 15.15 16.61 15.15M7.41 15.15C6.95 15.15 6.57 14.78 6.57 14.32C6.57 13.86 6.95 13.5 7.41 13.5H7.41C7.87 13.5 8.24 13.86 8.24 14.32C8.24 14.78 7.87 15.15 7.41 15.15M16.91 10.14L18.58 7.26C18.67 7.09 18.61 6.88 18.45 6.79C18.28 6.69 18.07 6.75 18 6.92L16.29 9.83C14.95 9.22 13.5 8.9 12 8.91C10.47 8.91 9 9.24 7.73 9.82L6.04 6.91C5.95 6.74 5.74 6.68 5.57 6.78C5.4 6.87 5.35 7.08 5.44 7.25L7.1 10.13C4.25 11.69 2.29 14.58 2 18H22C21.72 14.59 19.77 11.7 16.91 10.14H16.91Z" /></svg> Android
                      </a>}
                      {item.ios && <a href={item.ios} className="w-100 justify-content-center btn btn-sm btn-outline-primary d-flex align-items-center ml-2" target="_blank" rel="noreferrer noopener">
                        <svg className="mr-1" xmlns="http://www.w3.org/2000/svg" width='24px' viewBox="0 0 24 24" fill="currentColor"><title>apple</title><path fill="inherit" d="M18.71,19.5C17.88,20.74 17,21.95 15.66,21.97C14.32,22 13.89,21.18 12.37,21.18C10.84,21.18 10.37,21.95 9.1,22C7.79,22.05 6.8,20.68 5.96,19.47C4.25,17 2.94,12.45 4.7,9.39C5.57,7.87 7.13,6.91 8.82,6.88C10.1,6.86 11.32,7.75 12.11,7.75C12.89,7.75 14.37,6.68 15.92,6.84C16.57,6.87 18.39,7.1 19.56,8.82C19.47,8.88 17.39,10.1 17.41,12.63C17.44,15.65 20.06,16.66 20.09,16.67C20.06,16.74 19.67,18.11 18.71,19.5M13,3.5C13.73,2.67 14.94,2.04 15.94,2C16.07,3.17 15.6,4.35 14.9,5.19C14.21,6.04 13.07,6.7 11.95,6.61C11.8,5.46 12.36,4.26 13,3.5Z" /></svg> iOS
                      </a>}
                    </div>
                  </div>
                </div>
              </div>))}
            </div>
          )}
          <h3 className="display-4 fw-800 text-dark opacity-85 mt-5 mb-1" id="contact-us">Contact Us</h3>
          <p>Connect with Innovation: Your Gateway to Tech Excellence</p>
          <ContactForm />
        </div>
      </div>
    </div>

    <div className="container-fluid pb-2 fw-200 opacity-50 small border-top pt-4 pb-2">
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1 z-index-1">
          <div className="row">
            <div className="col-12 col-lg-4 mb-3">
              <h6 className="mb-1">Address</h6>
              <p className="mb-0">B-31, North Ex Mall, Sector 9</p>
              <p className="mb-0">Rohini, Delhi, India 110085</p>
            </div>
            <div className="col-12 col-lg-4 mb-3">
              <h6 className="mb-1">Email</h6>
              <p className="mb-0">contact@jumpfast.tech</p>
            </div>
            <div className="col-12 col-lg-4 mb-3">
              <h6 className="mb-1">Contact</h6>
              <p className="mb-0">+91 7011308235</p>
            </div>
          </div>
          Copyright © {new Date().getFullYear()} jumpfast.tech | All rights reserved
        </div>
      </div>
    </div>
  </div>)
}

export default App;

